.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #0078ba;
  line-height: 1.27272727;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.25em;
  font-weight: normal;
  font-family: 'FrutigerNextBold', sans-serif;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #0068a1;
}
.button:active {
  background-color: #00476e;
}
.desk {
  max-width: 87.5vw;
}
#home {
  height: 80px;
  margin: 40px 0;
}
.section--header,
.section--multimood,
.section--separator {
  box-sizing: border-box;
  padding: 0 40px;
}
@media (max-width: 1024px) {
  .section--header,
  .section--multimood,
  .section--separator {
    padding: 0 20px;
  }
}
.section--footer {
  box-sizing: border-box;
  border-left: 40px solid #fff;
  border-right: 40px solid #fff;
}
@media (max-width: 1024px) {
  .section--footer {
    border-width: 0 20px;
  }
}
.footpart {
  width: 50%;
  margin: 32px 0;
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .cb-album .body,
.side .head,
.side .foot,
.side .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.side .tiny {
  width: 46%;
}
.side > .slim {
  width: 50%;
}
.side > .slim .head,
.side > .slim .foot,
.side > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.side > .slim.cb-album .body {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.side > .slim .tiny {
  width: 92%;
}
.farnorth {
  width: 100%;
}
.farnorth > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farnorth .cb-album .body,
.farnorth .head,
.farnorth .foot,
.farnorth .part {
  margin-right: 2%;
  margin-left: 2%;
  width: 96%;
}
.farnorth .tiny {
  width: 46%;
}
.farnorth > .slim {
  width: 50%;
}
.farnorth > .slim .head,
.farnorth > .slim .foot,
.farnorth > .slim .part {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.farnorth > .slim.cb-album .body {
  margin-right: 4%;
  margin-left: 4%;
  width: 92%;
}
.farnorth > .slim .tiny {
  width: 92%;
}
.side {
  width: 104%;
  margin-left: -2%;
}
.farnorth {
  width: 104%;
  margin-left: -2%;
}
.side .flat {
  margin-left: 2%;
  margin-right: 2%;
}
.side .flat.wide {
  width: 96%;
}
.side .flat.slim {
  width: 46%;
}
/*# sourceMappingURL=./screen-medium.css.map */